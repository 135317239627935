import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';
import { useBreakpoint } from 'ts/common/hooks';
import type { IBookingSessionType } from 'client_react/booking/types';
import BookingSessionTypeCard from './BookingSessionTypeCard';

interface IBookingSessionTypeGridProps {
    bookingSessionTypes: IBookingSessionType[];
    buttonText: string | null;
    onClickBookingSessionType: (bookingSessionType: IBookingSessionType) => void;
}

const BookingSessionTypeGrid: React.FC<IBookingSessionTypeGridProps> = ({
    bookingSessionTypes,
    buttonText,
    onClickBookingSessionType
}) => {
    const isLargeScreen = useBreakpoint();

    return (
        <Grid
            templateColumns={{
                base: 'repeat(1, 1fr)',
                lg: 'repeat(3, 1fr)'
            }}
            gap={isLargeScreen ? '60px' : '80px'}
            padding={4}
        >
            {bookingSessionTypes.map((bookingSessionType) => (
                <GridItem key={bookingSessionType.publicId}>
                    <BookingSessionTypeCard
                        bookingSessionType={bookingSessionType}
                        buttonText={buttonText}
                        onClick={() => onClickBookingSessionType(bookingSessionType)}
                    />
                </GridItem>
            ))}
        </Grid>
    );
};

export default BookingSessionTypeGrid;
