import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, styled } from 'sp-ui';
import { LoadingIndicator } from 'ts/common/components';
import BookingSessionTypeGrid from 'client_react/booking/components/BookingSessionTypeGrid';
import { IBookingSessionType } from 'client_react/booking/types';
import { useClientApiFetch } from 'client_react/bootstrap';
import { TileContainer } from '../components/common';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';

const LoadingContainer = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    width: 100%;
`;

const GridContainer = styled(TileContainer)`
    padding: 0 20px;
    margin-top: 24px;

    @media only screen and (min-width: 632px) {
        padding: 0;
    }
`;

const BookingPage: React.FC = () => {
    const navigate = useNavigate();
    const { bookingPrimaryButtonText } = useBrandHomepage();
    const { response: bookingSessionTypesResponse } =
        useClientApiFetch<{ items: IBookingSessionType[] }>('booking-session-type');

    const handleClickBookingSessionType = (sessionType: IBookingSessionType) => {
        navigate(`/booking/${sessionType.slug}`);
    };

    if (!bookingSessionTypesResponse?.items) {
        return (
            <LoadingContainer>
                <LoadingIndicator />
            </LoadingContainer>
        );
    }

    return (
        <GridContainer>
            <BookingSessionTypeGrid
                bookingSessionTypes={bookingSessionTypesResponse?.items}
                buttonText={bookingPrimaryButtonText}
                onClickBookingSessionType={handleClickBookingSessionType}
            />
        </GridContainer>
    );
};

export default BookingPage;
