import React, { FC } from 'react';
import { Box, Button, Text, VStack, useTheme } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import type { IBookingSessionType } from 'client_react/booking/types';
import { BookingSessionTypeCover } from './BookingSessionTypeCover';

interface IBookingSessionTypeCardProps {
    bookingSessionType: IBookingSessionType;
    buttonText: string | null;
    onClick: () => void;
}

const BookingSessionTypeCard: FC<IBookingSessionTypeCardProps> = ({
    bookingSessionType,
    buttonText,
    onClick
}) => {
    const t = useTranslate();
    const theme = useTheme();
    const { name, hasPrice, description, price, images, duration, durationUnit } =
        bookingSessionType;

    const getDurationString = () => {
        if (!duration || !durationUnit) {
            return '';
        }

        const unit =
            durationUnit === 'minute'
                ? duration === 1
                    ? t('booking.brandHomepage.minute')
                    : t('booking.brandHomepage.minutes')
                : duration === 1
                ? t('booking.brandHomepage.hour')
                : t('booking.brandHomepage.hours');
        return `${duration} ${unit}`;
    };

    const imageUrl = images?.[0]?.url;
    const textColor = theme.brand.colors.theme.secondary.base;
    const buttonTextColor = theme.brand.colors.theme.primary;

    return (
        <Box
            overflow="hidden"
            transition="transform 0.2s"
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                position="relative"
                width="100%"
                paddingBottom="66.67%"
                overflow="hidden"
                backgroundColor={imageUrl ? 'transparent' : 'gray.100'}
            >
                {imageUrl ? (
                    <Box
                        as="img"
                        src={imageUrl}
                        alt={name || ''}
                        position="absolute"
                        top="0"
                        left="0"
                        width="100%"
                        height="100%"
                        objectFit="contain"
                    />
                ) : (
                    <Box position="absolute" top="0" left="0" width="100%" height="100%">
                        <BookingSessionTypeCover title={name} />
                    </Box>
                )}
            </Box>
            <VStack flex="1" alignItems="center" spacing={2} padding={6}>
                <Text
                    fontSize="18px"
                    fontWeight={500}
                    fontFamily={`${theme.brand.fonts.primary}`}
                    color={theme.brand.colors.user.primary}
                >
                    {name}
                </Text>
                <Text fontSize="16px" fontWeight={600} color={textColor} marginTop="auto">
                    {hasPrice ? `${price} /` : null}
                    {duration ? ` ${getDurationString()}` : null}
                </Text>
                <Text fontSize="md" color={textColor} flex="1">
                    {description}
                </Text>
            </VStack>
            <Button
                onClick={onClick}
                borderRadius="33px"
                color={buttonTextColor}
                backgroundColor={theme.brand.colors.user.primary}
                width="300px"
                alignSelf="center"
                _hover={{
                    backgroundColor: theme.brand.colors.user.primary,
                    opacity: 0.8,
                    transform: 'scale(1.02)'
                }}
                transition="all 0.2s"
            >
                {buttonText ? buttonText : t('booking.brandHomepage.primaryButtonText')}
            </Button>
        </Box>
    );
};

export default BookingSessionTypeCard;
